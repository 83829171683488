import eIconPicker from 'e-icon-picker';
import "e-icon-picker/lib/symbol.js"; //基本彩色图标库
import 'e-icon-picker/lib/index.css'; // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库

import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/display.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from "./config"
import api from './api'
import tool from './utils/tool'
import http from "./utils/request"
import VueUeditorWrap from 'vue-ueditor-wrap';

// import 'element-ui/lib/theme-chalk/icon.css'; //element-ui 图标库

import paging from './components/paging.vue'
import uploaderimgfile from './components/uploaderImgfile.vue'
import ktable from'./components/k-table.vue'
import has from'./utils/Permissions.js';

const app = createApp(App);

//挂载全局对象
app.config.globalProperties.$CONFIG = config;
app.config.globalProperties.$TOOL = tool;
app.config.globalProperties.$HTTP = http;
app.config.globalProperties.$API = api;

app.use(store);
//console.log(zhlocale);
app.use(router);
//app.use(ElementPlus,);
app.use(VueUeditorWrap);

app.use(eIconPicker, { FontAwesome: true, ElementUI: true, eIcon: true, eIconSymbol: true });

app.component('paging', paging);
app.component('uploaderimgfile', uploaderimgfile);
app.component('k-table', ktable);

app.use(ElementPlus, { locale });
app.use(has);

// 挂载app
app.mount('#app');

