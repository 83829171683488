<template>
    <div>
        <!-- <el-config-provider :locale="locale"> -->
            <el-pagination background :current-page="currentPage" :page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="sizeChanges" @current-change="currentChanges" />
        <!-- </el-config-provider> -->
    </div>
</template>

<script>
//引入vue方法
// import { ElConfigProvider } from "element-plus";
// //中文包
// import zhCn from "element-plus/lib/locale/lang/zh-cn";

export default {
    name: "Paging",
    components: {
        // [ElConfigProvider.name]: ElConfigProvider,
    },
    props: {
        total: { type: Number, default: 0 }, //总条数
        pagesize: { type: Number, default: 10 }, //每页条数
        currentPage: { type: Number, default: 1 }, //当前页码
        pagesizes:{type:[],default:[10, 20, 30, 50, 100]},
    },
    setup() {
        // let locale = zhCn;
        // return {
        //     locale,
        // };
    },
    data() {
        return {};
    },
    methods: {
        sizeChanges(val) {
            this.$emit("sizeChange", val);
        },
        currentChanges(val) {
            this.$emit("currentChange", val);
        },
    },
};
</script>
<style>
.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
    width: auto;
}
</style>