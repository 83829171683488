import axios from 'axios';
import { ElNotification } from 'element-plus';
import sysConfig from "@/config";
import tool from '@/utils/tool';
import router from '@/router';

axios.defaults.baseURL = ''

axios.defaults.timeout = 600000

axios.defaults.withCredentials = false;

// HTTP request 拦截器
axios.interceptors.request.use(
	(config) => {
		let adminInfo = tool.data.get("adminInfo");
		if (adminInfo) {
			config.headers[sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + adminInfo.JwtToken;
		}
		if (!sysConfig.REQUEST_CACHE && config.method == 'get') {
			config.params = config.params || {};
			config.params['_'] = new Date().getTime();
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// HTTP response 拦截器
axios.interceptors.response.use(
	(response) => {
		if (response.data.code == 401) {
			ElNotification.error({
				title: '请求错误',
				message: response.data.Message
			});
			router.replace({ path: '/login' });
		}
		else if (response.data.code == 403) {
			ElNotification.error({
				title: '请求错误',
				message: response.data.message
			});
			return response.data;
		}
		else if (response.data.Code == 200) {
			return response.data.Data;
		}
		else if(response.data.Code==500){
			ElNotification.error({
				title: '请求错误',
				message: response.data.Message
			});
			return response.data;
		}
		else
			return response.data;
	},
	(error) => {
		if (error.response) {
			if (error.response.status == 404) {
				ElNotification.error({
					title: '请求错误',
					message: "Status:404，正在请求不存在的服务器记录！"
				});
			} else if (error.response.status == 500) {
				ElNotification.error({
					title: '请求错误',
					message: "Status:500，服务器发生错误！"
				});
			} else {
				ElNotification.error({
					title: '请求错误',
					message: `Status:${error.response.status}，未知错误！`
				});
			}
		} else {
			ElNotification.error({
				title: '请求错误',
				message: "请求服务器无响应！"
			});
		}
		console.log(error);
		return Promise.reject(error.response);
	}
);

export default axios;
