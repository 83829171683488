import config from "@/config";
import axios from "@/utils/request";
import fileDownload from "js-file-download";

/**
 * 所有接口集合
 * 每个接口对象需含有以下字段
 * @url 接口的URL地址
 * @name 接口名称
 * @get|post 返回请求接口的函数
 */

const api = {
	// 登录
	AdminAuth: {
		GetCaptcha: {
			url: `${config.API_URL}/AdminAuth/GetCaptcha`,
			name: "获取验证码",
			post: async function () {
				var result = await axios.post(this.url, {});
				return result;
			},
		},
		AdminLogin: {
			url: `${config.API_URL}/AdminAuth/AdminLogin`,
			name: "用户登录",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//员工管理
	Admin: {
		QueryByPage: {
			url: `${config.API_URL}/Admin/QueryByPage`,
			name: "获取员工数据表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Admin/AddOrUpdate`,
			name: "员工信息新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Admin/FakeDelete`,
			name: "删除员工数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/Admin/QueryById`,
			name: `根据员工id获取员工信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?id=${id}`);
				return result;
			},
		},
		GenerateImportTemplate: {
			url: `${config.API_URL}/Admin/GenerateImportTemplate`,
			name: `下载员工信息导入模板`,
			post: async function () {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
				}).then((res) => {
					fileDownload(res, `员工信息导入模板${new Date().Format("yyyyMMddHHmmss")}.xlsx`);
				});
			},
		},
		AdminImporter: {
			url: `${config.API_URL}/Admin/AdminImporter`,
			name: `员工信息导入`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},
		ExportExcel: {
			url: `${config.API_URL}/Admin/ExportExcel`,
			name: `员工信息导出`,
			post: async function (data) {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
					data,
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `员工信息${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
		ImportAdminPhoto: {
			url: `${config.API_URL}/Admin/ImportAdminPhoto`,
			name: `导入员工照片`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},
		ExportAdminPhoto: {
			url: `${config.API_URL}/Admin/ExportAdminPhoto`,
			name: `员工照片导出`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		UniSyncAdminData: {
			url: `${config.API_URL}/Admin/UniSyncAdminData`,
			name: `教职工数据同步宇泛`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		YqshSyncAdminData: {
			url: `${config.API_URL}/Admin/YqshSyncAdminData`,
			name: `教职工数据同步一起生活`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetAdminsByRoles: {
			url: `${config.API_URL}/Admin/GetAdminsByRoles`,
			name: `根据角色获取员工信息`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//菜单
	Menu: {
		GetMenuList: {
			url: `${config.API_URL}/Menu/GetMenuList`,
			name: "获取菜单树",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/Menu/QueryById`,
			name: "根据id获取数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Menu/AddOrUpdate`,
			name: "新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Menu/FakeDelete`,
			name: "删除数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
	},
	//组织
	Org: {
		GetOrgList: {
			url: `${config.API_URL}/Org/GetOrgList`,
			name: "获取菜单树",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/Org/QueryById`,
			name: "根据id获取数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Org/AddOrUpdate`,
			name: "新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Org/FakeDelete`,
			name: "删除数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
	},
	//学校--班级
	Classes: {
		QueryByPage: {
			url: `${config.API_URL}/Classes/QueryByPage`,
			name: "获取班级数据表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Classes/AddOrUpdate`,
			name: "班级新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		ClassAddBatch: {
			url: `${config.API_URL}/Classes/ClassAddBatch`,
			name: "批量创建班级",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Classes/FakeDelete`,
			name: "删除班级数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryByClassId: {
			url: `${config.API_URL}/Classes/QueryByClassId`,
			name: `根据班级id获取班级信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?classId=${id}`);
				return result;
			},
		},
		GetClassesDataList: {
			url: `${config.API_URL}/Classes/GetClassesDataList`,
			name: `获取班级数据列表`,
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		GetGradeList: {
			url: `${config.API_URL}/Classes/GetGradeList`,
			name: `获取年级`,
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		GetClassListByGrade: {
			url: `${config.API_URL}/Classes/GetClassListByGrade`,
			name: "根据年级获取班级列表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//学校--学生
	Student: {
		QueryByPage: {
			url: `${config.API_URL}/Student/QueryByPage`,
			name: "获取学生数据表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Student/AddOrUpdate`,
			name: "学生信息新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Student/FakeDelete`,
			name: "删除学生数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryByStudentId: {
			url: `${config.API_URL}/Student/QueryByStudentId`,
			name: `根据学生id获取学生信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?stuid=${id}`);
				return result;
			},
		},
		GenerateImportTemplate: {
			url: `${config.API_URL}/Student/GenerateImportTemplate`,
			name: `下载学生导入模板`,
			post: async function () {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
				}).then((res) => {
					fileDownload(res, `学生导入模板${new Date().Format("yyyyMMddHHmmss")}.xlsx`);
					// let url = window.URL.createObjectURL(new Blob([res]));
					// let link = document.createElement("a");
					// link.style.display = "none";
					// link.href = url;
					// var date = new Date();
					// link.setAttribute(
					// 	"download",
					// 	`学生导入模板${date.Format("yyyyMMddHHmmss")}.xlsx`
					// );
					// document.body.appendChild(link);
					// link.click();
				});
			},
		},
		StudentInfoImporter: {
			url: `${config.API_URL}/Student/StudentInfoImporter`,
			name: `导入学生基本信息`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},
		ExportExcel: {
			url: `${config.API_URL}/Student/ExportExcel`,
			name: `学生信息导出`,
			post: async function (data) {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
					data,
				}).then((res) => {
					//fileDownload(res, `学生信息${new Date().Format("yyyyMMddHHmmss")}.xlsx`);
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `学生信息${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
		GetStudentListByClass: {
			url: `${config.API_URL}/Student/GetStudentListByClass`,
			name: "根据班级获取学生列表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetServiceItem: {
			url: `${config.API_URL}/Student/GetServiceItem`,
			name: "获取服务项目",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		BatchProcesServiceItem: {
			url: `${config.API_URL}/Student/BatchProcesServiceItem`,
			name: `服务项目办理`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		BatchCancelProcesServiceItem: {
			url: `${config.API_URL}/Student/BatchCancelProcesServiceItem`,
			name: `取消服务项目办理`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		BatchProcesServiceImportTemplate: {
			url: `${config.API_URL}/Student/BatchProcesServiceImportTemplate`,
			name: `下载学生服务办理批量导入处理模板`,
			post: async function () {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `学生服务办理批量导入处理模板${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
		GetStudentServiceProcesInfo: {
			url: `${config.API_URL}/Student/GetStudentServiceProcesInfo`,
			name: `根据学生id获取学生服务办理信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?stuid=${id}`);
				return result;
			},
		},
		ImportStudentPhoto: {
			url: `${config.API_URL}/Student/ImportStudentPhoto`,
			name: `导入学生照片`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},
		ExportStudentPhoto: {
			url: `${config.API_URL}/Student/ExportStudentPhoto`,
			name: `学生照片导出`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		UniSyncStudentData: {
			url: `${config.API_URL}/Student/UniSyncStudentData`,
			name: `宇泛学生数据同步`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		YqshSyncStudentData: {
			url: `${config.API_URL}/Student/YqshSyncStudentData`,
			name: `学生数据同步一起生活`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		TaideSyncStudentData: {
			url: `${config.API_URL}/Student/TaideSyncStudentData`,
			name: `学生数据同步泰德`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetStudentList: {
			url: `${config.API_URL}/Student/GetStudentList`,
			name: `获取学生列表`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetStudentsList: {
			url: `${config.API_URL}/Student/GetStudentsList`,
			name: `获取学生列表`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		DownCardInfoImportTemplate: {
			url: `${config.API_URL}/Student/DownCardInfoImportTemplate`,
			name: `下载校园卡信息导入模板`,
			post: async function () {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
				}).then((res) => {
					fileDownload(res, `校园卡信息导入模板${new Date().Format("yyyyMMddHHmmss")}.xlsx`);
				});
			},
		},
		BatchCardInfoImporter: {
			url: `${config.API_URL}/Student/BatchCardInfoImporter`,
			name: `导入校园卡信息`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},

	},
	//学校--学生门禁考勤
	SchoolChecks: {
		QueryByPage: {
			url: `${config.API_URL}/SchoolChecks/QueryByPage`,
			name: "获取门禁考勤数据表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//学校--学生家长
	StudentParent: {
		AddOrUpdate: {
			url: `${config.API_URL}/StudentParent/AddOrUpdate`,
			name: "学生家长信息添加或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetStudentParentsByStuId: {
			url: `${config.API_URL}/StudentParent/GetStudentParentsByStuId`,
			name: `根据学生id获取学生家长信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?stuid=${id}`);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/StudentParent/FakeDelete`,
			name: "删除学生家长数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
	},
	//组织权限
	OrgAuthority: {
		QueryByOrgId: {
			url: `${config.API_URL}/OrgAuthority/QueryByOrgId`,
			name: `根据组织id获取权限信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?orgId=${id}`);
				return result;
			},
		},
		QueryOrgByOrgId: {
			url: `${config.API_URL}/OrgAuthority/QueryOrgByOrgId`,
			name: `根据组织id获取菜单信息`,
			get: async function () {
				var result = await axios.get(`${this.url}`);
				return result;
			},
		},
		AddOrgAuthor: {
			url: `${config.API_URL}/OrgAuthority/AddOrgAuthor`,
			name: "添加组织权限信息",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//角色
	Role: {
		GetRoleAll: {
			url: `${config.API_URL}/Role/GetRoleAllByAdminId`,
			name: "获取所有角色",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		GetMenuByRoleId: {
			url: `${config.API_URL}/Role/GetMenuByRoleId`,
			name: "获取所有角色",
			get: async function (id) {
				var result = await axios.get(`${this.url}?roleId=${id}`);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Role/AddOrUpdate`,
			name: "新增修改角色",
			post: async function (data) {
				var result = await axios.post(`${this.url}`, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Role/FakeDelete`,
			name: "删除数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		AddRoleAuthor: {
			url: `${config.API_URL}/Role/AddRoleAuthor`,
			name: "添加组织权限信息",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetRoleAlls: {
			url: `${config.API_URL}/Role/GetRoleAll`,
			name: "获取所有角色",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//微信配置表
	WechatConfig: {
		QueryByPage: {
			url: `${config.API_URL}/WechatConfig/QueryByPage`,
			name: "获取微信配置表数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/WechatConfig/AddOrUpdate`,
			name: "微信配置表新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/WechatConfig/FakeDelete`,
			name: "删除微信配置表数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/WechatConfig/QueryById`,
			name: "根据id获取数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
	},
	//微信配置关联组织表
	WeChatConfigSysOrg: {
		AddOrUpdate: {
			url: `${config.API_URL}/WeChatConfigSysOrg/AddOrUpdate`,
			name: "关联组织信息添加或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetWeChatConfigSysOrgs: {
			url: `${config.API_URL}/WeChatConfigSysOrg/GetWeChatConfigSysOrgs`,
			name: `根据配置id获取关联组织信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?configId=${id}`);
				return result;
			},
		},
		GetSchoolOrgList: {
			url: `${config.API_URL}/WeChatConfigSysOrg/GetSchoolOrgList`,
			name: `根据配置id获取关联组织信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?configId=${id}`);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/WeChatConfigSysOrg/FakeDelete`,
			name: "删除关联组织信息",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
	},
	//微信模板消息配置
	WeChatConfigTemplateMsg: {
		QueryByPage: {
			url: `${config.API_URL}/WeChatConfigTemplateMsg/QueryByPage`,
			name: "获取微信模板消息配置表数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/WeChatConfigTemplateMsg/AddOrUpdate`,
			name: "微信模板消息配置表新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/WeChatConfigTemplateMsg/FakeDelete`,
			name: "删除微信模板消息配置表数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/WeChatConfigTemplateMsg/QueryById`,
			name: "根据id获取模板消息数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetMsgTypes:{
			url: `${config.API_URL}/WeChatConfigTemplateMsg/GetMsgTypes`,
			name: "获取模板消息类型",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		}
	},
	//科目表
	SchoolSubject: {
		QueryByPage: {
			url: `${config.API_URL}/SchoolSubject/QueryByPage`,
			name: "获取科目表数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/SchoolSubject/AddOrUpdate`,
			name: "科目表新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/SchoolSubject/FakeDelete`,
			name: "删除科目表数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/SchoolSubject/QueryById`,
			name: "根据id获取数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetSchoolSubjects: {
			url: `${config.API_URL}/SchoolSubject/GetSchoolSubjects`,
			name: "获取科目数据",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
	},
	//任课
	ClassTeacherSubject: {
		GetClassTeacherSubjects: {
			url: `${config.API_URL}/SchoolClassTeacher/GetClassTeacherSubjects`,
			name: `根据班级id获取班级任课信息`,
			get: async function (id) {
				var result = await axios.get(`${this.url}?classId=${id}`);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/SchoolClassTeacher/FakeDelete`,
			name: "删除班级任课信息",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/SchoolClassTeacher/AddOrUpdate`,
			name: "任课信息添加或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GenerateImportTemplate: {
			url: `${config.API_URL}/SchoolClassTeacher/GenerateImportTemplate`,
			name: `下载任课导入模板`,
			post: async function () {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `任课信息导入模板${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
		CtsImporter: {
			url: `${config.API_URL}/SchoolClassTeacher/CtsImporter`,
			name: `导入任课信息`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},
		ExportExcel: {
			url: `${config.API_URL}/SchoolClassTeacher/ExportExcel`,
			name: `任课信息导出`,
			post: async function (data) {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
					data,
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `任课信息${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
		GetWorkerList: {
			url: `${config.API_URL}/SchoolClassTeacher/GetWorkerList`,
			name: "获取职工列表",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
	},
	//用户管理
	SysUser: {
		QueryByPage: {
			url: `${config.API_URL}/SysUser/QueryByPage`,
			name: "获取用户列表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		ResetPassword: {
			url: `${config.API_URL}/SysUser/ResetPassword`,
			name: "重置密码",
			post: async function (id) {
				var result = await axios.post(`${this.url}?id=${id}`);
				return result;
			},
		},
	},
	//轮播图表
	Carousel: {
		QueryByPage: {
			url: `${config.API_URL}/Carousel/QueryByPage`,
			name: "获取轮播图表数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/Carousel/AddOrUpdate`,
			name: "轮播图表新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/Carousel/FakeDelete`,
			name: "删除轮播图表数据",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/Carousel/QueryById`,
			name: "根据id获取数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
	},
	//学生通知
	SysNotice: {
		QueryByPage: {
			url: `${config.API_URL}/SysNotice/QueryByPage`,
			name: "获取通知数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/SysNotice/AddOrUpdate`,
			name: "通知新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/SysNotice/FakeDelete`,
			name: "删除通知",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/SysNotice/QueryById`,
			name: "根据id获取通知数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetNoticeTarGetList: {
			url: `${config.API_URL}/SysNoticeTarget/QueryByPage`,
			name: "获取通知对象数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		NoticeTargetFakeDelete: {
			url: `${config.API_URL}/SysNoticeTarget/DeleteNoticeTarget`,
			name: "删除通知对象",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		GetWokerDataByClassTeacher: {
			url: `${config.API_URL}/SysNotice/GetWokerDataByClassTeacher`,
			name: "获取班主任教职工",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//服务项目管理
	ServiceItem: {
		QueryByPage: {
			url: `${config.API_URL}/ServiceItem/QueryByPage`,
			name: "获取服务项目数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/ServiceItem/AddOrUpdate`,
			name: "服务项目新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/ServiceItem/FakeDelete`,
			name: "删除服务项目",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/ServiceItem/QueryById`,
			name: "根据id获取服务项目数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetAllServiceItem: {
			url: `${config.API_URL}/ServiceItem/GetAllServiceItem`,
			name: "获取服务项目列表",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		IsCloseServiceItem: {
			url: `${config.API_URL}/ServiceItem/IsCloseServiceItem`,
			name: "开启或关闭服务办理",
			post: async function (id) {
				var result = await axios.post(`${this.url}?Id=${id}`);
				return result;
			},
		},
	},
	//服务项目办理记录管理
	ServiceItemOrderRecord: {
		QueryByPage: {
			url: `${config.API_URL}/ServiceItemOrderRecord/QueryByPage`,
			name: "获取服务项目办理数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		Rfund: {
			url: `${config.API_URL}/ServiceItemOrderRecord/Rfund`,
			name: "退款",
			post: async function (id) {
				var result = await axios.post(`${this.url}/${id}`);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/ServiceItemOrderRecord/FakeDelete`,
			name: "删除",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		ExportExcel: {
			url: `${config.API_URL}/ServiceItemOrderRecord/ExportExcel`,
			name: `办理记录导出`,
			post: async function (data) {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
					data,
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `服务办理记录${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
	},
	//宇泛应用配置
	UfaceConfig: {
		QueryByPage: {
			url: `${config.API_URL}/UfaceConfig/QueryByPage`,
			name: "获取宇泛应用配置数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/UfaceConfig/AddOrUpdate`,
			name: "宇泛应用配置新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/UfaceConfig/FakeDelete`,
			name: "删除宇泛应用配置",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/UfaceConfig/QueryById`,
			name: "根据id获取宇泛应用配置数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetUfaceConfigList: {
			url: `${config.API_URL}/UfaceConfig/GetUfaceConfigList`,
			name: "获取配置列表",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
	},
	//宇泛设备管理
	UfaceDevice: {
		QueryByPage: {
			url: `${config.API_URL}/UfaceDevice/QueryByPage`,
			name: "获取宇泛设备数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/UfaceDevice/AddOrUpdate`,
			name: "宇泛设备新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/UfaceDevice/FakeDelete`,
			name: "删除宇泛设备",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/UfaceDevice/QueryById`,
			name: "根据id获取宇泛设备数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		UfaceDeviceReset: {
			url: `${config.API_URL}/UfaceDevice/UfaceDeviceReset`,
			name: "设备重置",
			post: async function (deviceKey) {
				var result = await axios.post(`${this.url}?deviceKey=${deviceKey}`);
				return result;
			},
		},
		UfaceDeviceRestart: {
			url: `${config.API_URL}/UfaceDevice/UfaceDeviceRestart`,
			name: "设备重启",
			post: async function (deviceKey) {
				var result = await axios.post(`${this.url}?deviceKey=${deviceKey}`);
				return result;
			},
		},
	},
	//宇泛授权组管理
	UfaceGroup: {
		QueryByPage: {
			url: `${config.API_URL}/UfaceGroup/QueryByPage`,
			name: "获取宇泛授权组数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/UfaceGroup/AddOrUpdate`,
			name: "宇泛授权组新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/UfaceGroup/FakeDelete`,
			name: "删除宇泛授权组",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/UfaceGroup/QueryById`,
			name: "根据id获取宇泛授权组数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
	},
	//宇泛授权组设备管理
	UfaceGroupDevice: {
		QueryByPage: {
			url: `${config.API_URL}/UfaceGroupDevice/QueryByPage`,
			name: "获取宇泛授权组设备数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetNoBindDeviceList: {
			url: `${config.API_URL}/UfaceGroupDevice/GetNoBindDeviceList`,
			name: "获取设备",
			post: async function (groupGuid) {
				var result = await axios.post(`${this.url}?groupGuid=${groupGuid}`);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/UfaceGroupDevice/AddOrUpdate`,
			name: "宇泛授权组设备添加",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/UfaceGroupDevice/FakeDelete`,
			name: "移除宇泛授权组设备",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
	},
	//宇泛人员管理
	UfacePerson: {
		QueryByPage: {
			url: `${config.API_URL}/UfacePerson/QueryByPage`,
			name: "获取宇泛人员数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/UfacePerson/AddOrUpdate`,
			name: "宇泛人员新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/UfacePerson/FakeDelete`,
			name: "删除宇泛人员",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/UfacePerson/QueryById`,
			name: "根据id获取宇泛人员数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetUfaceGroups: {
			url: `${config.API_URL}/UfacePerson/GetUfaceGroups`,
			name: "获取授权组数据",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		UfaceGroupPersonAuth: {
			url: `${config.API_URL}/UfacePerson/UfaceGroupPersonAuth`,
			name: `宇泛人员授权`,
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//宇泛授权组人员管理
	UfaceGroupPerson: {
		QueryByPage: {
			url: `${config.API_URL}/UfaceGroupPerson/QueryByPage`,
			name: "获取宇泛授权组人员数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/UfaceGroupPerson/FakeDelete`,
			name: "移除宇泛授权组内人员",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
	},
	//一起生活配置
	YqshConfig: {
		QueryByPage: {
			url: `${config.API_URL}/YqshConfig/QueryByPage`,
			name: "获取一起生活配置数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/YqshConfig/AddOrUpdate`,
			name: "一起生活配置新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/YqshConfig/FakeDelete`,
			name: "删除一起生活配置",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/YqshConfig/QueryById`,
			name: "根据id获取一起生活配置数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetYqshConfigList: {
			url: `${config.API_URL}/YqshConfig/GetYqshConfigList`,
			name: "获取一起生活配置列表",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		GetCampus: {
			url: `${config.API_URL}/YqshConfig/GetCampus`,
			name: "获取sp关联校区信息",
			get: async function (sp) {
				var result = await axios.get(`${this.url}/${sp}`);
				return result;
			},
		},
		GetYqshCampus: {
			url: `https://face.cdyqsh.com/admin-service/adminservice/openApi/getCampus`,
			name: "获取sp关联校区信息",
			get: async function (sp) {
				var result = await axios.get(`${this.url}?sp=${sp}`);
				return result;
			},
		},
		OnOrOffShieldingNotHandled: {
			url: `${config.API_URL}/YqshConfig/OnOrOffShieldingNotHandled`,
			name: "开启或者关闭屏蔽未办理学生",
			post: async function (id) {
				var result = await axios.post(`${this.url}?Id=${id}`);
				return result;
			},
		},
	},
	//一起生活部门
	YqshDepartment: {
		QueryByPage: {
			url: `${config.API_URL}/YqshDepartment/QueryByPage`,
			name: "获取一起生活部门数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/YqshDepartment/FakeDelete`,
			name: "删除一起生活部门",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		CreateWorkerDept: {
			url: `${config.API_URL}/YqshDepartment/CreateWorkerDept`,
			name: "教职工部门同步",
			post: async function (configId) {
				var result = await axios.post(`${this.url}?configId=${configId}`);
				return result;
			},
		},
		CreateStudentDept: {
			url: `${config.API_URL}/YqshDepartment/CreateStudentDept`,
			name: "学生班级部门同步",
			post: async function (configId) {
				var result = await axios.post(`${this.url}?configId=${configId}`);
				return result;
			},
		},
		OnOrOffSyncData: {
			url: `${config.API_URL}/YqshDepartment/OnOrOffSyncData`,
			name: "开启或者关闭人员数据同步",
			post: async function (id) {
				var result = await axios.post(`${this.url}?Id=${id}`);
				return result;
			},
		},
		GetYqshDepartments: {
			url: `${config.API_URL}/YqshDepartment/GetYqshDepartments`,
			name: "获取一起生活部门列表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//一起生活人员
	YqshPerson: {
		QueryByPage: {
			url: `${config.API_URL}/YqshPerson/QueryByPage`,
			name: "获取一起生活人员信息列表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/YqshPerson/FakeDelete`,
			name: "删除一起生活人员信息",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		CreateWorkerDept: {
			url: `${config.API_URL}/YqshDepartment/CreateWorkerDept`,
			name: "教职工部门同步",
			post: async function (configId) {
				var result = await axios.post(`${this.url}?configId=${configId}`);
				return result;
			},
		},
		CreateStudentDept: {
			url: `${config.API_URL}/YqshDepartment/CreateStudentDept`,
			name: "学生班级部门同步",
			post: async function (configId) {
				var result = await axios.post(`${this.url}?configId=${configId}`);
				return result;
			},
		},
		OnOrOffSyncData: {
			url: `${config.API_URL}/YqshDepartment/OnOrOffSyncData`,
			name: "开启或者关闭人员数据同步",
			post: async function (id) {
				var result = await axios.post(`${this.url}?Id=${id}`);
				return result;
			},
		},
	},
	//泰德配置
	TaideConfig: {
		QueryByPage: {
			url: `${config.API_URL}/TaideConfig/QueryByPage`,
			name: "获取泰德配置数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/TaideConfig/AddOrUpdate`,
			name: "宇泛泰德配置新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/TaideConfig/FakeDelete`,
			name: "删除泰德配置",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/TaideConfig/QueryById`,
			name: "根据id获取泰德配置数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetTaideConfigList: {
			url: `${config.API_URL}/TaideConfig/GetTaideConfigList`,
			name: "获取泰德配置列表",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
	},
	//海康设备管理
	HK: {
		GroupsListAll: {
			url: `${config.API_URL}/HK/GroupsListAll`,
			name: "获取所有海康设备信息",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		GroupsIni: {
			url: `${config.API_URL}/HK/GroupsIni`,
			name: "初始化设备组",
			post: async function () {
				var result = await axios.post(this.url);
				return result;
			},
		},
		GroupsDelete: {
			url: `${config.API_URL}/HK/GroupsDelete`,
			name: "删除设备组",
			post: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.post(this.url, deleteInput);
				return result;
			},
		},
		DevicesList: {
			url: `${config.API_URL}/HK/DevicesList`,
			name: "获取设备列表",
			get: async function (groupNo) {
				var result = await axios.get(this.url + `/` + groupNo);
				return result;
			},
		},
		DevicesCreate: {
			url: `${config.API_URL}/HK/DevicesCreate`,
			name: "注册设备",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		DevicesDelete: {
			url: `${config.API_URL}/HK/DevicesDelete`,
			name: "注销设备",
			post: async function (data) {
				var result = await axios.post(this.url + "/" + data);
				return result;
			},
		},
		DevicesUpdate: {
			url: `${config.API_URL}/HK/DevicesUpdate`,
			name: "修改设备名称",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//校园卡
	CardInfo: {
		QueryByPage: {
			url: `${config.API_URL}/CardInfo/QueryByPage`,
			name: "获取卡数据列表",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		GetPersonHandCardInfo: {
			url: `${config.API_URL}/CardInfo/GetPersonHandCardInfo`,
			name: "获取人员办卡信息",
			get: async function (personType, personId, cardType) {
				var result = await axios.get(`${this.url}?personType=${personType}&personId=${personId}&cardType=${cardType}`);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/CardInfo/AddOrUpdate`,
			name: "校园卡信息管理",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
	},
	//投标设备管理
	BidDevices: {
		QueryByPage: {
			url: `${config.API_URL}/BidDevices/QueryByPage`,
			name: "获取投标设备数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/BidDevices/AddOrUpdate`,
			name: "设备新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/BidDevices/FakeDelete`,
			name: "删除设备",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/BidDevices/QueryById`,
			name: "根据id获取设备数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GenerateImportTemplate: {
			url: `${config.API_URL}/BidDevices/GenerateImportTemplate`,
			name: `下载设备导入模板`,
			post: async function () {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
				}).then((res) => {
					fileDownload(res, `设备导入模板${new Date().Format("yyyyMMddHHmmss")}.xlsx`);
				});
			},
		},
		BidDevicesImporter: {
			url: `${config.API_URL}/BidDevices/BidDevicesImporter`,
			name: `导入设备信息`,
			post: async function (id) {
				var result = await axios.post(`${this.url}?fileId=${id}`);
				return result;
			},
		},
		ExportExcel: {
			url: `${config.API_URL}/BidDevices/ExportExcel`,
			name: `设备信息导出`,
			post: async function (data) {
				await axios({
					url: this.url,
					method: "post",
					responseType: "blob",
					data,
				}).then((res) => {
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					var date = new Date();
					link.setAttribute("download", `设备信息${date.Format("yyyyMMddHHmmss")}.xlsx`);
					document.body.appendChild(link);
					link.click();
				});
			},
		},
	},
	//投标文件存档
	BidFiles: {
		QueryByPage: {
			url: `${config.API_URL}/BidFiles/QueryByPage`,
			name: "获取投标文件存档数据",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		AddOrUpdate: {
			url: `${config.API_URL}/BidFiles/AddOrUpdate`,
			name: "文件存档新增或者修改",
			post: async function (data) {
				var result = await axios.post(this.url, data);
				return result;
			},
		},
		FakeDelete: {
			url: `${config.API_URL}/BidFiles/FakeDelete`,
			name: "删除文件存档",
			delete: async function (ids) {
				var deleteInput = {
					Ids: ids,
				};
				var result = await axios.delete(this.url, {
					data: deleteInput,
				});
				return result;
			},
		},
		QueryById: {
			url: `${config.API_URL}/BidFiles/QueryById`,
			name: "根据id获取文件存档数据",
			get: async function (id) {
				var result = await axios.get(`${this.url}/${id}`);
				return result;
			},
		},
		GetBidFileTypes:{
			url: `${config.API_URL}/BidFiles/GetBidFileTypes`,
			name: "获取存档文件类型",
			get: async function () {
				var result = await axios.get(this.url);
				return result;
			},
		},
		Downloadfile: {
			url: `${config.API_URL}/File/DownloadFileInfo`,
			name: `下载存档文件`,
			get: async function (id,name) {
				await axios({
					url: `${this.url}?Id=${id}`,
					method: "get",
					responseType: "blob",
				}).then((res) => {
					fileDownload(res,name);
				});
			},
		},
	},
};
export default api;
