<template>
  <el-upload
    class="avatar-uploader"
    :action="`${this.$CONFIG.API_URL}/File/UploadFileAvatar`"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    :headers="headers"
  >
    <img v-if="imageUrl.Url" :src="imageUrl.Url" class="avatar" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>
<script>
export default {
  name: "UploaderImgfile",
  props: {
    imageUrl: {
      type: Object,
      default(){
        return {}
      },
    },
  },
  data() {
    return {
      headers: {},
    };
  },
  mounted() {
    this.$nextTick(function () {
      // 仅在整个视图都被渲染之后才会运行的代码
      let adminInfo = this.$TOOL.data.get("adminInfo");
      this.headers = {
        Authorization: "Bearer " + adminInfo.JwtToken,
      };
    });
  },
  methods: {
    handleAvatarSuccess(res) {
      this.$emit("handleAvatarSuccess", res);
    },
    beforeAvatarUpload(file) {
      const fileType = file.type;
      const isImg = !(
        fileType != ".jpg" &&
        fileType != ".JPG" &&
        fileType != "image/jpeg" &&
        fileType != ".png" &&
        fileType != ".PNG" &&
        fileType != "image/png" &&
        fileType != ".gif" &&
        fileType != ".GIF" &&
        fileType != "image/gif"
      );
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
