//
export default (Vue) => {
    Vue.directive('has', {
        mounted(el, binding) {
            //获取按钮权限
            if (!Vue.config.globalProperties.$_has(binding.value)) {
                //移除不匹配的按钮
                el.parentNode.removeChild(el);
            }
        }
    });

    //检查权限方法
    Vue.config.globalProperties.$_has = function (value) {
        let isExist = false;
        var btnPerms = this.$route.meta.btn;
        var array = value.split(',');
        var count = 0;
        array.forEach(element => {
            if (btnPerms.indexOf(element) > -1)
                count += 1;
        });
        if (count > 0)
            isExist = true;
        return isExist;
    }
}