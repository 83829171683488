import { createRouter, createWebHashHistory } from 'vue-router';
import { ElNotification } from 'element-plus';
import config from "@/config"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import tool from '@/utils/tool';
import systemRouter from './systemRouter';
import { beforeEach, afterEach } from './scrollBehavior';
import axios from 'axios';

//系统路由
const routes = systemRouter


//系统特殊路由
const routes_404 = {
  path: "/:pathMatch(.*)*",
  hidden: true,
  component: () => import(/* webpackChunkName: "404" */ '@/views/other/404'),
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})

//设置标题
document.title = config.APP_NAME

//判断是否已加载过API路由
var isGetApiRouter = false;

//菜单
var menuAuthor = [];

router.beforeEach((to, from, next) => {
  NProgress.start()
  //动态标题
  document.title = `${to.meta.title} - ${config.APP_NAME}`

  let adminInfo = tool.data.get("adminInfo");

  if (to.path === "/login") {
    isGetApiRouter = false;
    next();
    return false;
  }

  if (!adminInfo) {
    next({
      path: '/login'
    });
    return false;
  }

  //重新获取菜单信息
  axios.get(`${config.API_URL}/Menu/GetMenuList`).then((res) => {
    //console.log('res', res);
    //加载API路由
    if (!isGetApiRouter) {
      var apiRouter = filterAsyncRouter(res);
      console.log('apiRouter', apiRouter);
      apiRouter.forEach(item => {
        router.addRoute("layout", item)
      })
      tool.data.set("apiRouter", apiRouter);
      router.addRoute(routes_404)
      if (to.matched.length == 0) {
        router.push(to.fullPath);
      }
      isGetApiRouter = true;
    }
    beforeEach(to, from)
    next();
  });


});

router.afterEach((to, from) => {
  afterEach(to, from)
  NProgress.done()
});

router.onError((error) => {
  NProgress.done();
  ElNotification.error({
    title: '路由错误',
    message: error.message
  });
});


//转换
function filterAsyncRouter(routerMap) {
  const accessedRouters = []
  routerMap.forEach(item => {
    item.meta = item.meta ? item.meta : {
      "title": item.MenuName,
      "icon": item.MenuPCIcon,
      "type": item.OpenType,
      "keepAlive": true,
      "menutype": item.MenuType,
      "btn": item.Children ? filterAsyncBtn(item.Children) : null,
    };
    //处理外部链接特殊路由
    if (item.meta.type == 3) {
      item.meta.url = item.MenuPCUrl;
      item.path = `/i/${item.name}`;
    }
    //MAP转路由对象
    var route = {
      path: item.MenuPCUrl,
      name: item.MenuAnotherName,
      meta: item.meta,
      redirect: item.Redirect,
      children: item.Children ? filterAsyncRouter(item.Children) : null,
      component: loadComponent(item.Component),
    }
    accessedRouters.push(route)
  })
  return accessedRouters
}
//按钮
function filterAsyncBtn(routerMap) {
  const accessedRouters = []
  routerMap.forEach(item => {
    if (item.MenuType == 2) {
      accessedRouters.push(item.MenuPCUrl.split('/')[2]);
    }
  })
  return accessedRouters
}
function loadComponent(component) {
  if (component) {
    return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}`)
  } else {
    return () => import(`@/views/other/empty`)
  }
}



export default router
