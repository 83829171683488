<template>
    <div>
        <el-main>
            <el-container>
                <el-row>
                    <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form :inline="true">
                            <el-input v-model="keyword" placeholder="请输入关键字查询" class="input-with-select" style="width:440px;margin-right:10px;">
                                <template #prepend>
                                    <el-select v-model="checkInput" multiple collapse-tags placeholder="请选择" style="width: 220px">
                                        <el-option v-for="(item, i) in  dataTableSearch" :key="i" :label="item.label" :value="item.attr"></el-option>
                                    </el-select>
                                </template>
                            </el-input>
                            <el-form-item label="">
                                <el-date-picker v-model="searchStartTime" type="date" placeholder="开始日期"></el-date-picker>
                                <el-date-picker v-model="searchEndTime" type="date" placeholder="结束日期"></el-date-picker>
                            </el-form-item>
                            <slot name="searchSlot"></slot>
                            <el-form-item>
                                <el-button v-if="buttonList.QueryButton" type="primary" icon="el-icon-search" size="medium" @click="SearchPage">查询</el-button>
                                <el-button type="success" icon="el-icon-edit" @click="resetForm(); dialogVisible=true" size="medium">新增</el-button>
                                <el-button type="warning" icon="el-icon-share" size="medium" @click="ExportExcel">导出</el-button>
                                <el-button type="danger" icon="el-icon-delete" size="medium" @click="deleteInfo">删除</el-button>
                                <el-popover placement="bottom" :width="400" trigger="click">
                                    <template #reference>
                                        <el-button><i class="el-icon-arrow-down el-icon-menu" /> 列筛选</el-button>
                                    </template>
                                    <el-checkbox-group v-model="dataTableSearchList">
                                        <el-checkbox v-for="(item, i) in dataTableList" :key="item" :label="item" checked style="display: block;margin:10px;" @change="filterFunHandle('filter',item.attr,i)">
                                            {{item.label}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                    <el-button size="small" type="text" @click="filterFunHandle('allchecked')">全选</el-button>
                                    <el-button size="small" type="text" @click="filterFunHandle('cancel')">取消全选</el-button>
                                </el-popover>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-table size="small" ref="multipleTable" :data="dataTable" style="width:99%;" :fit="true" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" fixed="left" header-align="center" align="center" width="50"></el-table-column>
                            <el-table-column label="操作" fixed="right" header-align="center" align="center">
                                <template #default="scope">
                                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column v-for="(col,index) in dataTableSearchList" :key="index" sortable header-align="center" align="center" :prop="col.attr" :label="col.label">
                                <template #default="scope">
                                    <el-image v-if="col.type=='img'" :src="scope.row[col.attr]" :preview-src-list="[scope.row[col.attr]]" fit="fill" style="width: 90px; height: 80px"></el-image>
                                    <el-tag size="medium" v-else-if="col.type=='enum'">{{col.formatters(scope.row[col.attr])}}</el-tag>
                                    <label v-else>{{scope.row[col.attr]}}</label>
                                </template>
                            </el-table-column>

                        </el-table>
                    </el-col>
                </el-row>
            </el-container>
            <el-footer style="height:60px;position:fixed;bottom:0;width:100%;">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[100, 200, 500, 1000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </el-footer>
            <el-dialog v-model="dialogVisible" title="员工管理" width="35%">
                <el-form ref="form" v-model="form" status-icon label-width="120px">
                    <!-- <div > -->
                    <el-form-item v-for="(col,index) in dataTableFrom.filter(it=>it.isInput!=false)" :key="index" :label="col.label" :rules="col.rules" :prop="col.attr">
                        <el-upload v-if="col.type=='img'" v-model="form[col.attr]" class="avatar-uploader" :action="`${this.$CONFIG.API_URL}/File/UploadFileAvatar`" :show-file-list="false" :on-success="function (res, file,fileList) { return handleAvatarSuccess(res, file,fileList, col.attr)} " :before-upload="beforeAvatarUpload" :headers="headers">
                            <img v-if="form[col.attr]" :src="this.imageUrl?this.imageUrl:form[col.attr]" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-select v-else-if="col.type=='enum'" v-model="form[col.attr]" placeholder="请选择">
                            <el-option v-for="item in col.dic" :key="item.value" :label="item.title" :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-else-if="col.type=='multiple'" v-model="form[col.attr]" multiple placeholder="请选择">
                            <el-option v-for="item in col.dic" :key="item.value" :label="item.title" :value="item.value">
                            </el-option>
                        </el-select>
                        <el-date-picker v-else-if="col.type=='date'" v-model="form[col.attr]" type="date" value-format="YYYY-MM-DD" placeholder="请选择">
                        </el-date-picker>
                        <el-input v-else v-model="form[col.attr]"></el-input>
                    </el-form-item>
                    <!-- </div> -->
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogVisible = false">关闭</el-button>
                        <el-button type="primary" @click="btnAdd">确定</el-button>
                    </span>
                </template>
            </el-dialog>
        </el-main>
    </div>
</template>
<script>
import "@element-plus/icons";
import config from "@/config";
import axios from "@/utils/request";
export default {
    name: "k-table",
    props: {
        //显示数据
        TableData: {
            type: Array,
            default: function () {
                return [{}];
            },
        },
        //from 表单信息
        FromData: {
            type: Object,
        },
        //弹出框标题
        TitleName: {
            type: String,
        },
        //请求控制器名称
        httpUrl: {
            type: String,
        },
        //按钮组
        ButtonList: {
            type: Object,
            //default: {
            // //新增按钮
            // AddBtutton: {
            //     show: true, //是否显示新增按钮
            //     fun: function () {
            //         //执行方法
            //     },
            // },
            // //删除按钮
            // DelButton: {
            //     show: true, //是否显示
            //     fun: function () {},
            // },
            // //查询按钮
            // QueryButton: {
            //     show: true,
            //     fun: function () {},
            // },
            // //导出按钮
            // ExportButton: {
            //     show: true,
            //     fun: function () {},
            // },
            //},
        },
    },

    data() {
        return {
            keyword: "", //搜索输入关键字
            checkInput: "", //选择所需要查询的内容
            dataTableSearch: [], //搜索信息
            dataTableList: [], //显示列信息
            dataTableSearchList: [], //列筛选信息
            dataTableFrom: [], //新增修改信息
            dataTable: [], //显示数据
            searchStartTime: "", //搜索开始时间
            searchEndTime: "", //搜索结束时间
            dialogVisible: false, //是否显示弹出框
            form: {}, //新增修改数据
            multipleSelection: [], //选中数据
            total: 400, //数据总条数
            pageSize: 100, //当前显示数据数量
            currentPage: 1, //分页当前页
            headers: {}, //图片上传时，headers配置
            tableHeight: 200, //table高度
            imageUrl: "",
            buttonList: {},
        };
    },
    mounted() {
        const that = this;

        that.$nextTick(function () {
            // 仅在整个视图都被渲染之后才会运行的代码
            //设置图片上传header 参数
            let adminInfo = that.$TOOL.data.get("adminInfo");
            that.headers = {
                Authorization: "Bearer " + adminInfo.JwtToken,
            };
            //设置table位置
            let heightStyle = window.innerHeight - 230;
            that.tableHeight = heightStyle;

            //窗口大小改变时，table 高度设置
            window.onresize = () => {
                return (() => {
                    let heightStyle = window.innerHeight - 230;
                    that.tableHeight = heightStyle;
                })();
            };
            that.SearchPage();
            //that.dataTableSearchList = that.TableData;
            that.dataTableSearchList = that.TableData.filter(
                (it) => it.isDisplay != false
            );
            that.dataTableSearch = that.TableData.filter(
                (it) => it.isInput != false
            );

            that.dataTableList = that.TableData.filter(
                (it) => it.isInput != false
            );
            that.dataTableFrom = that.TableData.filter(
                (it) => it.isInput != false
            );
            that.form = that.FromData;
            that.buttonList = that.ButtonList;
            console.log(that.buttonList);
            //console.log("dataTableSearchList", that.dataTableSearchList);
        });
    },
    watch: {
        TableData(val, oldval) {
            var that = this;
            that.SearchPage();

            that.dataTableSearchList = val.filter((it) => it.isInput != false);
            that.dataTableSearch = val.filter((it) => it.isInput != false);

            that.dataTableList = val.filter((it) => it.isInput != false);
            that.dataTableFrom = that.TableData.filter(
                (it) => it.isInput != false
            );
        },
        FromData(val) {
            this.form = val;
        },
    },
    methods: {
        //页面搜索查询
        SearchPage() {
            let that = this;
            let json = {
                searchValue: "",
                pageNo: that.currentPage,
                pageSize: that.pageSize,
                searchBeginTime: that.searchStartTime,
                searchEndTime: that.searchEndTime,
                sortField: "",
                sortOrder: "",
                descStr: "",
            };
            if (that.checkInput.length > 0 && that.keyword != "") {
                var searchParameters = [],
                    input = that.keyword;
                for (let index = 0; index < that.checkInput.length; index++) {
                    const element = that.checkInput[index];
                    var searchParametersItem = {
                        field: element,
                        op: 8,
                        value: input,
                        orGroup: "",
                    };
                    searchParameters.push(searchParametersItem);
                }
                json.searchParameters = searchParameters;
            }
            axios
                .post(`${config.API_URL}/${that.httpUrl}/QueryByPage`, json)
                .then((res) => {
                    console.log("SearchPage", res);
                    that.dataTable = res.Rows;
                    that.total = res.TotalRows;
                });
        },
        //修改操作
        handleEdit(index, row) {
            console.log(row);
            var info = row.RoleIds;
            var array = info.split(",").map(Number);
            var that = this;
            that.dataTableFrom.forEach((item) => {
                that.form[item.attr] = row[item.attr];
            });
            that.form.RoleName = array;
            that.form.AdminAvatar = row.AdminAvatar;
            that.form.Id = row.Id;
            console.log(that.form);
            that.dialogVisible = true;
        },
        //删除操作
        handleDelete(index, row) {},
        //新增修改
        btnAdd() {
            var that = this;
            console.log("that.form", that.form);
            that.form.RoleIds = that.form.RoleName;
            if (!this.form.AdminAvatar)
                that.form.AdminAvatar = that.form.AdminAvatarUrl;
            that.form.AdminType = 3;
            //if (2 > 1) return;
            that.$refs["form"].validate((valid) => {
                console.log("valid", valid);
                if (valid) {
                    console.log(this.form);
                    axios
                        .post(
                            `${config.API_URL}/${that.httpUrl}/AddOrUpdate`,
                            that.form
                        )
                        .then((res) => {
                            console.log(res);

                            that.dataTableSearchList = res.Rows;
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //重置输入框内容
        resetForm() {
            var that = this;
            this.dataTableFrom.forEach((it) => {
                that.form[it.attr] = "";
            });
        },
        //删除
        deleteInfo() {
            // 这里的 multipleTable 是上面ref的值。 直接访问子组件实例上的属性。
            //console.log(this.multipleSelection);
            var Ids = [];
            var that = this;
            var json = {};
            for (const key in this.multipleSelection) {
                if (Object.hasOwnProperty.call(this.multipleSelection, key)) {
                    const element = this.multipleSelection[key];
                    Ids.push(element.Id);
                }
            }
            json.Ids = Ids;
            axios
                .delete(`${config.API_URL}/${that.httpUrl}/FakeDelete`, {
                    data: json,
                })
                .then((res) => {
                    //console.log(res);
                    that.SearchPage();
                });
        },
        handleAvatarSuccess(res, file, fileList, obj) {
            this.imageUrl = URL.createObjectURL(file.raw);
            //console.log(res);
            this.form[obj] = res.Data.Id;
            //this.$emit("handleAvatarSuccess", res);
        },
        handleSelectionChange(val) {
            //console.info(val);
            this.multipleSelection = val;
        },
        // 列筛选
        filterFunHandle(type, currentItem, index) {
            if (type === "allchecked") {
                // 全选
                this.dataTableSearchList = this.TableData.filter(
                    (it) => it.isInput != false
                );
            } else if (type === "cancel") {
                // 取消全选
                this.dataTableSearchList = []; // 复选框置为空，全部不选择
            }
        },
        // 下载文件
        download(data) {
            if (!data) {
                return;
            }
            let url = window.URL.createObjectURL(new Blob([data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            var date = new Date();
            //date.Format('yyyyMMddHHmmss')
            link.setAttribute(
                "download",
                date.Format("yyyyMMddHHmmss") + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
        },
        //图片上传前判断
        beforeAvatarUpload(file) {
            const fileType = file.type;
            const isImg = !(
                fileType != ".jpg" &&
                fileType != ".JPG" &&
                fileType != "image/jpeg" &&
                fileType != ".png" &&
                fileType != ".PNG" &&
                fileType != "image/png" &&
                fileType != ".gif" &&
                fileType != ".GIF" &&
                fileType != "image/gif"
            );
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isImg) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }
            return isImg && isLt2M;
        },
    },
};
</script>
<style>
.adminui-main {
    background-color: white;
}

.el-form-item__content div {
    display: inline-block;
}
.el-form-item__content .el-input {
    margin-right: 10px;
}
.el-date-editor .el-range-separator {
    width: 25px;
}
.el-form-item__label {
    text-align: center;
}
</style>